html, body {
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;
}

html, body, p, h1, h2, h3, h4, h5, h6, span, i, b, textarea {
  font-family: 'Montserrat', sans-serif;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1C2023; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
